import React from "react";
import ImprovedGrid from "./grid/improvedGrid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

class NeneParkGridDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userSubmissions: [],
            selectedArea: null // This will store the currently selected area for filtering
        };
    }

    componentDidMount() {
        this.modifySubmissions();
    }

    componentDidUpdate(prevProps, prevState) {
        // Recalculate submissions when selectedArea changes
        if (prevState.selectedArea !== this.state.selectedArea) {
            this.modifySubmissions();
        }
    }

    modifySubmissions = () => {
        const { extendedSubmissions } = this.props;
        const { selectedArea } = this.state;

        // Filter submissions based on the selected area, or keep all if no area is selected
        const filteredSubmissions = selectedArea
            ? extendedSubmissions.filter(submission => submission.area === selectedArea)
            : extendedSubmissions;

        const userSubmissionsMap = {};

        // Group submissions by userId (uid) and accumulate total badges for the selected area
        filteredSubmissions.forEach(submission => {
            const { uid, firstName, lastName, class: className } = submission;

            if (!userSubmissionsMap[uid]) {
                userSubmissionsMap[uid] = {
                    uid,
                    firstName,
                    lastName,
                    class: className,
                    badge: 0, // Initialize badge count
                    characteristicsCompleted: {} // Track characteristics completed by user
                };
            }

            // Increment the badge count for this user
            userSubmissionsMap[uid].badge += 1;

            // Track which characteristics have been completed
            if (submission.characteristic) {
                userSubmissionsMap[uid].characteristicsCompleted[submission.characteristic] = {
                    completed: true,
                    pledge: submission.pledge || '',
                    description: submission.description || ''
                };
            }
        });

        // Convert the userSubmissionsMap object into an array of user data
        const userSubmissions = Object.values(userSubmissionsMap);

        // Update state with the aggregated data
        this.setState({ userSubmissions });
    }

    // This function will handle area selection
    handleAreaClick = (area) => {
        this.setState({ selectedArea: area });
    }

    render() {
        const { userSubmissions, selectedArea } = this.state;
        const { areas } = this.props;

        // Filter out areas where stage is "secondary" and sort by index
        const filteredAndSortedAreas = areas
            .filter(area => area.stage !== "secondary")
            .sort((a, b) => a.index - b.index);

        // Find the characteristics for the selected area, or gather all characteristics when no area is selected (i.e., "All Areas" is selected)
        const selectedAreaCharacteristics = selectedArea
            ? areas.find(area => area.path === selectedArea)?.characteristics || []
            : filteredAndSortedAreas.flatMap(area => area.characteristics || []);

        // Prepare columns: basic columns + dynamic columns for characteristics (name, pledge, description)
        const columns = [
            { key: 'firstName', label: 'First Name' },
            { key: 'lastName', label: 'Last Name' },
            { key: 'class', label: 'Class' },
            { key: 'badge', label: 'Badge Count' },
            ...selectedAreaCharacteristics.flatMap(characteristic => ([
                { key: `${characteristic.name}_completed`, label: `${characteristic.name}` },
                { key: `${characteristic.name}_pledge`, label: `${characteristic.name} Pledge` },
                { key: `${characteristic.name}_description`, label: `${characteristic.name} Description` }
            ]))
        ];

        return (
            <div className="container">
                <h1 className="my-3">All User Submissions</h1>

                {/* Display buttons for each area */}
                <div className="mb-3">
                    <button 
                        className="btn btn-primary me-2 mb-2" 
                        onClick={() => this.handleAreaClick(null)}
                    >
                        All Areas
                    </button>
                    {filteredAndSortedAreas.map(area => (
                        <button
                            key={area.path}
                            className="btn btn-secondary me-2 mb-2"
                            onClick={() => this.handleAreaClick(area.path)}
                        >
                            {area.name}
                        </button>
                    ))}
                </div>

                {/* Display currently selected area */}
                {selectedArea && <h2 className="my-3">Showing submissions for: {selectedArea}</h2>}

                {/* Pass the filtered submissions to the ImprovedGrid */}
                <ImprovedGrid
                    extendedSubmissions={userSubmissions.map(submission => {
                        // For each user submission, map characteristics to a tick if completed, and include pledge and description
                        const characteristicsData = selectedAreaCharacteristics.reduce((acc, characteristic) => {
                            const charCompleted = submission.characteristicsCompleted[characteristic.name] || {};
                            acc[`${characteristic.name}_completed`] = charCompleted.completed
                                ? <FontAwesomeIcon icon={faCheck} />
                                : ''; // Empty if not completed
                            acc[`${characteristic.name}_pledge`] = charCompleted.pledge || ''; // Pledge or empty
                            acc[`${characteristic.name}_description`] = charCompleted.description || ''; // Description or empty
                            return acc;
                        }, {});

                        return {
                            ...submission,
                            ...characteristicsData // Merge characteristic ticks, pledge, and description into submission
                        };
                    })}
                    columns={columns}
                />
            </div>
        );
    }
}

export default NeneParkGridDashboard;

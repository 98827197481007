import React, { useRef, useEffect } from 'react';

// Assuming postData is passed as a prop to this component
const SocialMediaPost = ({ postData }) => {
    const canvasRef = useRef(null);
    const downloadLinkRef = useRef(null);

    // Extract and parse the JSON string from postData
    let parsedData = { quote: '', statement: '' };
    try {
        const extractJsonFromString = (str) => {
            const jsonStart = str.indexOf('{');
            const jsonEnd = str.lastIndexOf('}') + 1; // +1 to include the last bracket
            if (jsonStart !== -1 && jsonEnd !== -1 && jsonEnd > jsonStart) {
                const jsonString = str.substring(jsonStart, jsonEnd);
                try {
                    return JSON.parse(jsonString);
                } catch (error) {
                    console.error('Error parsing JSON', error);
                    return null;
                }
            } else {
                console.error('JSON structure not found');
                return null;
            }
        };

        // Use the function to extract and parse the JSON
        const jsonString = postData.description[0].text.value;
        parsedData = extractJsonFromString(jsonString);
    } catch (error) {
        console.error('Error parsing JSON from postData', error);
    }

    const wrapText = (context, text, x, y, maxWidth, lineHeight) => {
        const words = text.split(' ');
        let line = '';

        for (let n = 0; n < words.length; n++) {
            const testLine = line + words[n] + ' ';
            const metrics = context.measureText(testLine);
            const testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                context.fillText(line, x, y);
                line = words[n] + ' ';
                y += lineHeight;
            } else {
                line = testLine;
            }
        }
        context.fillText(line, x, y);
    };

    const drawCanvas = () => {
        const canvas = canvasRef.current;
        if (!canvas) return; // Exit if canvas is not initialized
        const context = canvas.getContext('2d');
        const quote = parsedData.quote; // Use the quote from parsedData
        const author = "";
        const maxWidth = 380; // Maximum width for the text
        const lineHeight = 25; // Line height for the text
        const fontSize = 12; // Font size for the text
        const padding = 20; // Padding around the canvas edges
        const textPadding = 50; // Additional padding for text
        const lineSpacing = 20; // Spacing between lines
        const authorSpacing = 40; // Spacing before the author
        const lineThickness = 5; // Thickness of the decorative lines
        const quoteMarkSize = 80; // Size of the quotation mark
    
        // Function to calculate the height of the wrapped text
        function calculateTextHeight(text, maxWidth, lineHeight) {
            const words = text.split(' ');
            let line = '';
            let height = 0;
            words.forEach(word => {
                let testLine = line + word + ' ';
                let metrics = context.measureText(testLine);
                let testWidth = metrics.width;
                if (testWidth > maxWidth && line !== '') {
                    line = word + ' ';
                    height += lineHeight;
                } else {
                    line = testLine;
                }
            });
            height += lineHeight; // Add the last line
            return height;
        }
    
        // Function to wrap text
        function wrapText(context, text, x, y, maxWidth, lineHeight) {
            const words = text.split(' ');
            let line = '';
    
            words.forEach(function(word) {
                const testLine = line + word + ' ';
                const metrics = context.measureText(testLine);
                const testWidth = metrics.width;
                if (testWidth > maxWidth && line !== '') {
                    context.fillText(line, x, y);
                    line = word + ' ';
                    y += lineHeight;
                } else {
                    line = testLine;
                }
            });
            context.fillText(line, x, y);
        }
    
        // Calculate the height needed for the text and adjust canvas height accordingly
        const textHeight = calculateTextHeight(quote, maxWidth - (textPadding * 2), lineHeight);
        canvas.height = padding + lineThickness + lineSpacing + textHeight + authorSpacing + (padding * 2) + (lineSpacing * 2) + lineThickness;
    
        // Draw background
        context.fillStyle = '#193d3d'; 
        context.fillRect(0, 0, canvas.width, canvas.height);
    
        // Draw the top line with a break
        context.fillStyle = '#fd5f2a'; 
        context.fillRect(0, padding, (canvas.width ) / 2 - quoteMarkSize / 2, lineThickness);
        context.fillRect(padding + (canvas.width / 2) + (quoteMarkSize / 2), padding, (canvas.width / 2) - padding - (quoteMarkSize / 2), lineThickness);
    
        // Draw the large quotation mark
        context.fillStyle = '#00c4cc'; 
        context.font = `${quoteMarkSize}px Arial`;
        context.fillText('❞', (canvas.width  / 2)-15, ((canvas.width - padding) / 8)+35);
    
        // Set up text styles for the quote
        context.fillStyle = 'white';
        context.textAlign = 'center';
        context.font = `bold ${fontSize}px Arial`;
    
        // Draw the quote text below the top line
        const topLineY = padding + lineThickness + lineSpacing;
        wrapText(context, '"'+quote+'"', canvas.width / 2, topLineY + 15, maxWidth - (textPadding * 2), lineHeight);
    
        context.fillStyle = '#fd5f2a'; 
        // Draw the bottom line after the quote text
        const bottomLineY = topLineY + textHeight + lineSpacing+10;
    
        context.fillStyle = '#00c4cc'; 
        // Draw the attribution
        const attributionY = bottomLineY + lineThickness + authorSpacing+10;
        context.font = `italic ${fontSize}px Arial`;
        context.fillText(`${author}`, canvas.width / 2, attributionY);
    };
    
    
    


    useEffect(() => {
        drawCanvas();
    }, [postData]); // Re-draw canvas when postData changes

    const handleDownload = () => {
        const canvas = canvasRef.current;
        const imageUrl = canvas.toDataURL('image/png');
        const downloadLink = downloadLinkRef.current;
        downloadLink.href = imageUrl;
        downloadLink.download = 'social-media-post.png';
        downloadLink.click();
    };

    return (
        <div>
            <div className="card border-primary">
                <canvas className="card-img-top" onClick={handleDownload} ref={canvasRef} style={{ maxWidth: '100%', height: 'auto', display: 'block', marginBottom: '10px' }}> </canvas>
                {/* <div className="card-body">
                    {/* <p className="card-text" style={{ fontSize: "0.8rem" }}>{parsedData.statement}</p> Use the .statement from parsedData 
                </div> */}
            </div>
        </div>
    );
};

export default SocialMediaPost;

import React from "react";

import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import ViewSubmissions from "./viewSubmissions";
import TeacherNavBar from "./teacherNavBar";
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faGear, faAward, faQuestionCircle, faPersonChalkboard } from '@fortawesome/free-solid-svg-icons'
import { getDatabase, ref, get, onValue } from 'firebase/database';
import { getAuth, signOut } from "firebase/auth";
import app from "../firebase";
import ManageStaff from "./manageStaff";
import SettingsPage from "./settings/settingsPage";
import TeacherChatHome from "../chat/teacherChatHome";
import ReportGeneratorHome from "./reportGenerator/reportGeneratorHome";






class TeacherPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = { schoolData: null }
    }

    componentDidMount() {
        this.getSchoolData()


    }

    getSchoolData = () => {
        const database = getDatabase(app);
        const schoolDatabasePath = ref(database, 'schools/' + this.props.user.school + '/public');

        onValue(schoolDatabasePath, (snapshot) => {
            const data = snapshot.val();
            console.log("from Database", data)
            if (data) {
                this.setState({ schoolData: data })
            } else {

            }
        });
    }


    render() {

        return (

            <>
                <div>
                    <TeacherNavBar schoolData={this.state.schoolData} user={this.props.user} />


                    <Routes>
                        <Route path='/' element={<div>

                            {this.state.schoolData?.safeStart ? <div><TeacherChatHome schoolData={this.state.schoolData} /> </div> :

                                <div style={{ padding: "20px" }} className="row">
                                    <div className="col-6" style={{ padding: "30px" }}>

                                        <Link style={{ textDecoration: 'none' }} to="/marking" >
                                            <div className="card text-white bg-primary mb-3 quizChoice" style={{ textAlign: "center", maxWidth: "18rem" }}>
                                                <div>
                                                    <div style={{ textAlign: "center" }} className="card-body">
                                                        <h3 style={{ textAlign: "right" }} className="card-title col-9">Students</h3>
                                                        <FontAwesomeIcon size={"6x"} icon={faUsers} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>


                                    <div className="col-6" style={{ width: "50%", padding: "30px" }}>
                                        <Link style={{ textDecoration: 'none' }} to="/settings" >
                                            <div className="card text-black bg-white mb-3 quizChoice" style={{ textAlign: "center", maxWidth: "18rem" }}>
                                                <div style={{ textAlign: "center" }} className="card-body">
                                                    <h3 style={{ textAlign: "right" }} className="card-title col-9">Settings</h3>
                                                    <FontAwesomeIcon size={"6x"} icon={faGear} />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>


                                    <div className="col-6" style={{ padding: "30px" }}>
                                        <div className="card" >

                                            <div className="card-body">
                                                <h3 className="card-title "><FontAwesomeIcon icon={faQuestionCircle} />   Getting Started</h3>
                                                <hr />
                                                <p><b>Important!</b> Students need to create their own accounts using the link below:</p>
                                                <a target="_blank" href={"https://app.beworldclass.org/student/" + this.props.user.school}>app.beworldclass.org/student/{this.props.user.school}</a>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-6" style={{ padding: "30px" }}>
                                        <div className="card">

                                            <div className="card-body">
                                                <h3 className="card-title "><FontAwesomeIcon icon={faPersonChalkboard} />   Additional resources</h3>
                                                <hr />

                                                <p>Coming soon</p>

                                            </div>
                                        </div>
                                    </div>


                                </div>

                            }
                        </div>} />

                        {this.state.schoolData &&
                            <>
                                <Route path='/marking' element={<ViewSubmissions schoolData={this.state.schoolData} />} />
                                <Route path='/staff' element={<ManageStaff schoolData={this.state.schoolData} />} />
                                <Route path='/settings/*' element={<SettingsPage schoolData={this.state.schoolData} />} />
                                <Route path='/gpt' element={<TeacherChatHome schoolData={this.state.schoolData} />} />
                                <Route path='/report-generator' element={<ReportGeneratorHome schoolData={this.state.schoolData} />} />
                            </>
                        }
                    </Routes>

                </div>
            </>
        );
    }
}

export default TeacherPage;